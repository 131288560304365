import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import Img from "gatsby-image"
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={entry.frontmatter.banner.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.banner.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <strong>About</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2 grid__gap--48">
                            <div className="text">
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <Link to="/contact/" className="btn btn__margin btn__blue btn__small">Make enquiry &rsaquo;</Link>
                            </div>
                            <div>
                                <div className="team">
                                    <h3>Directors</h3>
                                    <ul>
                                        <li><Link to="/about/tamzin-davies/">Tamzin Davies</Link> &rsaquo;</li>
                                        <li><Link to="/about/dan-thompson/">Dan Thompson</Link> &rsaquo;</li>
                                    </ul>
                                </div>
                                <div className="team">
                                    <h3>Account Managers</h3>
                                    <ul>
                                        <li><Link to="/about/sue-trombik/">Sue Trombik</Link> &rsaquo;</li>
                                        <li><Link to="/about/berny-tinneny-phillips/">Berny Tinneny Phillips</Link> &rsaquo;</li>
                                    </ul>
                                </div>

                                <div className="team">
                                    <Img fluid={entry.frontmatter.support.childImageSharp.fluid} alt="Support &amp; Extended Team" /> 
                                    <h3>Support &amp; Extended Team</h3>
                                    <p>The Staff Checks team is made by a diverse collective of professionals and large-scale organisations. Located in various corners of the world, our experts work diligently to earn their spot as most trusted in the industry. </p>
                                    <p>Because we understand the importance of hiring the right employee, you can rest assured that each member of our extended team has been chosen for a specific reason. Be it their sharp analytical skills, account management expertise, or their high standard of efficiency; our specialists bring defined and developed qualities which enable excellence throughout our assistance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
                meta_description
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                banner {
                    heading
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1024) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                support {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`